<template>
   <div id="box">
      <h1>生活质量问卷</h1>
      <p>选择最符合自己情况的选项。</p>

      <h4>1.您觉得自己的身体健康状况如何？</h4>
      <el-radio-group v-model="radio">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>2.您觉得自己的精力如何？</h4>
      <el-radio-group v-model="radio1">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio>
      </el-radio-group>
      <br />
      <h4>3.您最近的情绪如何？</h4>
      <el-radio-group v-model="radio2">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <br />
      <h4>4.您觉得您现在的居住环境如何？</h4>
      <el-radio-group v-model="radio3">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>5.您目前的记忆力如何？</h4>
      <el-radio-group v-model="radio4">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>6.您的家庭状况和您与家人的关系如何？</h4>
      <el-radio-group v-model="radio5">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>7.您觉得您的婚姻状况怎样？</h4>
      <el-radio-group v-model="radio6">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>8.您会怎样形容您目前和朋友之间的关系？</h4>
      <el-radio-group v-model="radio7">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>9.您对自己的感觉如何？</h4>
      <el-radio-group v-model="radio8">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>10.您觉得自己做家务的能力或做其他必须做的事情的能力如何？</h4>
      <el-radio-group v-model="radio9">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>11.您进行自己喜欢的娱乐活动的能力如何？</h4>
      <el-radio-group v-model="radio10">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>12.您当前的经济状况如何？</h4>
      <el-radio-group v-model="radio11">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group>
      <h4>13.总的来说，您觉得您人生的整体状况如何？</h4>
      <el-radio-group v-model="radio12">
         <el-radio label="0">A：差</el-radio><br />
         <el-radio label="1">B：普通 </el-radio><br />
         <el-radio label="2">C：好</el-radio><br />
         <el-radio label="3">D: 非常好</el-radio><br />
      </el-radio-group><br>
      <el-button type="success"
                 @click="ExamSolutionADD"
                 :disabled="isdisabled">提交</el-button>
   </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
   mounted () {
      this.get();
   },
   updated () {
      // console.log("总分：", this.total);
      // console.log("说明", this.explain);
   },
   methods: {
      async ExamSolutionADD () {
         const data = await axios({
            url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=生活质量量表QOL-AD&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=/&Review=暂无建议&Section=/&Doctor=/`,
            method: "post",
            data,
         });
         if (data.data.data == true) {
            Message.success("提交成功");
            this.$router.push("/Assessment");
         }
      },
      //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
      get () {
         const patientN = localStorage.getItem("patientN");
         const patientI = localStorage.getItem("patientI");
         this.name = JSON.parse(patientN);
         this.cid = JSON.parse(patientI);
      },
      //  // 点击提交上传评分，并且删除本地存储的患者信息
      // test() {
      //   console.log(11111111, this.name);
      //   localStorage.removeItem("patientN");
      //   localStorage.removeItem("patientI");
      // },
   },
   computed: {
      // explain() {
      //   if (this.total < 6) {
      //     return "正常";
      //   }
      //   if (this.total >= 6 && this.total <= 9) {
      //     return "轻度嗜睡";
      //   }
      //   if (this.total >= 10 && this.total <= 16) {
      //     return "中度嗜睡";
      //   }
      //   if (this.total > 16) {
      //     return "重度嗜睡";
      //   }
      // },
      isdisabled () {
         if (
            this.radio == "" ||
            this.radio1 == "" ||
            this.radio2 == "" ||
            this.radio3 == "" ||
            this.radio4 == "" ||
            this.radio5 == "" ||
            this.radio6 == "" ||
            this.radio7 == "" ||
            this.radio8 == "" ||
            this.radio9 == "" ||
            this.radio10 == "" ||
            this.radio11 == "" ||
            this.radio12 == ""
         ) {
            return true;
         }
         if (
            this.radio != "" ||
            this.radio1 != "" ||
            this.radio2 != "" ||
            this.radio3 != "" ||
            this.radio4 != "" ||
            this.radio5 != "" ||
            this.radio6 != "" ||
            this.radio7 != "" ||
            this.radio8 != "" ||
            this.radio9 != "" ||
            this.radio10 != "" ||
            this.radio11 != "" ||
            this.radio12 != ""
         ) {
            return false;
         }
      },
      total () {
         return (
            this.radio * 1 +
            this.radio1 * 1 +
            this.radio2 * 1 +
            this.radio3 * 1 +
            this.radio4 * 1 +
            this.radio5 * 1 +
            this.radio6 * 1 +
            this.radio7 * 1 +
            this.radio8 * 1 +
            this.radio9 * 1 +
            this.radio10 * 1 +
            this.radio11 * 1 +
            this.radio12 * 1
         );
      },
   },
   data () {
      return {
         ETID: "E9",
         isShow: false,
         name: "",
         cid: "",
         radio: "",
         radio1: "",
         radio2: "",
         radio3: "",
         radio4: "",
         radio5: "",
         radio6: "",
         radio7: "",
         radio8: "",
         radio9: "",
         radio10: "",
         radio11: "",
         radio12: "",
      };
   },
};
</script>

<style lang="scss" scoped>
// @import "./style.scss";
.el-radio {
   margin: 10px;
}
body {
   background: rgb(220, 245, 179);
}
#box {
   width: 100%;
   margin: auto;
   text-align: left;
   margin-left: 30px;
   // background: rgb(206, 245, 175);
}
.label_color {
   color: rgb(202, 44, 207);
}
h1 {
   text-align: center;
}

h4 {
   color: rgb(140, 102, 245);
}
</style>
